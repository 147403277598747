require('./boats-controller');
require('./payments-controller');
require('./layout');
require('./bookings-controller');
require('./coupons-controller');
require('./admin-controller');
require('./companies-controller');
require('./products-controller');
require('./boat-client');
require('./support-controller');
require('./users-controller');
require('./app-controller');
require('./user_widgets-controller');

document.addEventListener("turbolinks:load", function() {

  //Index action
  if (document.querySelector('.index') && document.querySelector('#index')) {
    
  }
});