// index.js -> bundle.js
var QRCode = require('qrcode');

document.addEventListener("turbolinks:load", function() {
  if(document.querySelector('.coupons')) {
    const elements = document.querySelectorAll('.qr-code');
    console.log(elements);
     for (const element of elements) {
      url = element.dataset.url;
      QRCode.toCanvas(element, url)
    }
  };
});