import QrScanner from 'qr-scanner'; 
document.addEventListener("turbolinks:load", function() {
  if(document.querySelector('.user_widgets')) {
    if(document.querySelector('#add')) {
      let videoElem = document.getElementById('video');
      console.log('hej' + videoElem);
      //QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

      const qrScanner = new QrScanner(videoElem, result => window.location.href = result);
      qrScanner.start();
    }s 
  };
})