document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('.supports')) {
    if (document.getElementById('show')) {
      console.log('woho');
    }
    if (document.getElementById('chat')) {
      //check if someone is writing
      document.onkeypress = function (e) {
        const writer = document.getElementById('writer');
        const http = new XMLHttpRequest();
        http.open("GET", "/chats/show-typing?support_id=" + writer.dataset.supportid + "&userid=" + writer.dataset.user);
        http.send();
      };

      function postForm() {
        document.forms[0].submit();
      }
      function clearMessage() {
        document.getElementById('chat_message').value = '';
      }
      agentDropdown = document.getElementById('agent');
      agentDropdown.addEventListener('change', updateAgent);
      function updateAgent() {
        const supportId = agentDropdown.dataset.support_id
        const http = new XMLHttpRequest()
        http.open("GET", "/supports/" + supportId + "/set-agent/" + agentDropdown.value)
        http.send();
      }
    }
  }
})