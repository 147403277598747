import QrScanner from 'qr-scanner'; 

document.addEventListener("turbolinks:load", function() {
  if(document.querySelector('.boats')) {
    if(document.querySelector('#scan')) {
      let videoElem = document.getElementById('video');
      console.log(videoElem);
      QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

      const qrScanner = new QrScanner(videoElem, result => window.location.href = result);
      qrScanner.start();
    }
    if(document.querySelector('#in_session')) {
      console.log('hej');
      $('.logbook').click(function() {
        $('#logbook-modal').show();
      });
      $('.close').click(function(){
        $('.modal').hide();
      });
      $('.rescue').click(function() {
        $('#rescue-modal').show();
      });

      var countdown = document.getElementById("countdown").dataset.expire
      var js_time = (countdown * 1000)
      var countDownDate = new Date(countdown).getTime();
        // Update the count down every 1 second
        var x = setInterval(function() {
          // Get today's date and time
          var now = new Date().getTime();
          console.log(now);
          console.log(js_time)
          // Find the distance between now and the count down date
          var distance = js_time - now;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Display the result in the element with id="demo"
          document.getElementById("countdown").innerHTML = hours.toString() + "h "
          + minutes.toString() + "m " + seconds.toString() + "s ";

          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x);
            document.getElementById("countdown").innerHTML = "TIDEN UTGÅTT";
          }
          if (distance < 20000) {
            $('#end-of-time').show();
          }

        }, 1000);
      }
  };
})

