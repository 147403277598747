document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('.boat_client_test')) {
    if (document.querySelector('#rent')) {
      alert('hej');
      let wind = window;
      console.log(window.location);
      // Create a client instance
      client = new Paho.MQTT.Client("driver.cloudmqtt.com", 38732, "1");
      //Example client = new Paho.MQTT.Client("m11.cloudmqtt.com", 32903, "web_" + parseInt(Math.random() * 100, 10));

      // set callback handlers
      client.onConnectionLost = onConnectionLost;
      client.onMessageArrived = onMessageArrived;
      var options = {
        useSSL: true,
        userName: "zywlswsi",
        password: "UbAqNkCZilpt",
        onSuccess: onConnect,
        onFailure: doFail
      }

      // connect the client
      client.connect(options);

      // called when the client connects
      function onConnect() {
        // Once a connection has been made, make a subscription and send a message.
        console.log("connected");
        client.subscribe("1");
        message = new Paho.MQTT.Message("Hello CloudMQTT");
        message.destinationName = "/cloudmqtt";
        client.send(message);
      }

      function doFail(e) {
        console.log(e);
      }

      // called when the client loses its connection
      function onConnectionLost(responseObject) {
        if (responseObject.errorCode !== 0) {
          console.log("onConnectionLost:" + responseObject.errorMessage);
          console.log('re-establishing');
          client.connect(options);
        }
      }

      // called when a message arrives
      function onMessageArrived(message) {
        console.log("onMessageArrived:" + message.payloadString);
        console.log(wind);
        if (message.payloadString == 'Started') {
          wind.location = "/client/1/running";
        }
      }
    }
  }
})