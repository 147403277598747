document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('.bookings')) {
    if (document.getElementById('index')) {
      let caret = document.querySelector('.dropdown');
      let menu = document.querySelector('.menu');
      let dropdownList = document.querySelector('.dropdown-list');
      caret.addEventListener('click', function() {
        toggleMenu(dropdownList);
      });

      function toggleMenu(x) {
        if (x.offsetParent === null) {
          x.classList.add("show");
        } else {
          x.classList.remove("show");
        }
      }
    }

    if (document.getElementById('flow')) {
      // index.js -> bundle.js
      var QRCode = require('qrcode');
      const elements = document.querySelectorAll('.qr-code');
      console.log(elements);
      for (const element of elements) {
        url = element.dataset.url;
        QRCode.toCanvas(element, url)
      }
    }
  }
});