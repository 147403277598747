import jquery from 'jquery'
window.jQuery = jquery;
window.$ = jquery;
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("controllers")
require("custom")
require("trix")
require("@rails/actiontext")