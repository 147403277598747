import consumer from "./consumer"

consumer.subscriptions.create("SupportChannel", {
  connected() {
    console.log('support är uppkopplad')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const writer = document.getElementById('writer');
    if(writer.dataset.agent === "false") {
      document.getElementById(data.content.support_id).insertAdjacentHTML("afterbegin", "<div class='message'><strong>" + data.content.name + '</strong> - ' + data.content.message + "</div>");      
    } else {
      document.getElementById(data.content.support_id).insertAdjacentHTML("afterbegin", "<div class='message'><img style='width: 20px; border-radius: 5px; margin-right: 10px;' src='/bowter_inverted.png'><strong>" + data.content.name + '</strong> - ' + data.content.message + "</div>");      
    } 
    document.getElementById('chat_message').value = '';
    // Called when there's incoming data on the websocket for this channel
  },
  speak(message) {
    console.log('speaking');
    this.perform('speak', { message: message });
  }
});
