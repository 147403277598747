import consumer from "./consumer"

consumer.subscriptions.create("TypingChannel", {
  connected() {
    console.log('typing är uppkopplad')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const writer = document.getElementById('writer');
    if(writer.dataset.user != data.content.user_id) {
      document.querySelector('.writer_' + data.content.id).style.display = 'block'
      document.getElementById('name').innerHTML = data.content.agent_name
      setTimeout(()=> {
        document.querySelector('.writer_' + data.content.id).style.display = 'none'
      },4000)  
    }
  }
});
